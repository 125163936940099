import http from '@/common/js/http'

// 新增优惠卷
export const addCoupon = async (form) => {
    return await http('/galaxyCoupon/addCoupon', form, 'POST')
}

// 优惠卷列表
export const listCoupon = async (form) => {
    return await http('/galaxyCoupon/listCoupon', form, 'POST')
}

// 查看优惠卷 详情
export const couponDetail = async (id) => {
    return await http(`/galaxyCoupon/couponDetail/${id}`)
}

// 编辑优惠卷
export const updateCoupon = async (form) => {
    return await http('/galaxyCoupon/updateCoupon', form, 'POST')
}

// 未绑定优惠卷列表
export const noUsedListCoupon = async (form) => {
    return await http('/galaxyCoupon/noUsedListCoupon', form, 'POST')
}

// 新增活动
export const addActivities = async (form) => {
    return await http('/galaxyActivitiesApi/addActivities', form, 'POST')
}

// 活动列表
export const activitiesList = async (form) => {
    return await http('/galaxyActivitiesApi/activitiesList', form, 'POST')
}
// 查看活动 详情
export const activitiesDetail = async (id) => {
    return await http(`/galaxyActivitiesApi/activitiesDetail/${id}`)
}

// 编辑活动
export const updateActivities = async (form) => {
    return await http('/galaxyActivitiesApi/updateActivities', form, 'POST')
}

// 活动手动终止
export const offlineActivities = async (form) => {
    return await http('/galaxyActivitiesApi/offlineActivities', form, 'POST')
}
