<style lang="scss" scoped>

</style>

<template>
    <div>
        <el-main class="com-eTrip-section section-has-footer">
            <div class="eTrip-section-cont">
                <search-form class="mb10" @update:form="handleSearch"></search-form>
                <data-table :list="list"></data-table>
            </div>
        </el-main>

        <el-footer class="com-eTrip-footer" height="64px">
            <pagination-foot
                ref="paginationFoot"
                :total="pageInfo.total"
                @update:pageInfo="handlePageInfo">
            </pagination-foot>
        </el-footer>
    </div>
</template>
<script type="text/ecmascript-6">
import searchForm from './components/form'
import dataTable from './components/table'
import paginationFoot from '@/components/pagination-foot'
import {listCoupon} from '@/www/urls/promotionUrls'
import {getOrganizationList} from "@/urls";
import {couponsBusinessTypes} from '@/data'

export default {
    name: '',
    data() {
        return {
            pageInfo: {},
            list: [],
            orgList: [],
            form: {}
        }
    },
    components: {
        searchForm,
        dataTable,
        paginationFoot
    },
    //计算属性
    computed: {},
    //主件被加载完成
    activated() {
        this.getOrgList()
    },
    mounted() {
        this.getOrgList()
    },
    methods: {
        async getOrgList(){
            const res = await getOrganizationList();
            if (res) {
                this.orgList = res
                this.handleSearch()
            }
        },
        handleSearch(form) {
            if(form) this.form = form
            this.$refs.paginationFoot.changeCurrent(1)
        },
        handlePageInfo(pageInfo) {
            this.pageInfo = {
                ...this.pageInfo,
                ...pageInfo,
            }
            this.getList()
        },
        async getList() {
            let {
                    pageSize,
                    currentPage
                } = this.pageInfo,
                form = {
                    pageIndex: currentPage,
                    pageSize,
                    ...this.form
                };
            let typeList = JSON.parse(JSON.stringify(couponsBusinessTypes))
            const res = await listCoupon(form)
            if (res.success && res.data.data) {
                this.pageInfo.total = res.data.total
                let list = res.data.data.map(item => {
                    item.couponUseCityInfo = JSON.parse(item.couponUseCityInfo)
                    if(item.couponUseProductType) {
                        item.couponTypeList = JSON.parse(item.couponUseProductType)
                        let typeArr = item.couponTypeList.map(typeItem => {
                            let typeObj = {}
                            typeList.map(typeItem1 => {
                                if (typeItem === typeItem1.value) {
                                    typeObj = typeItem1
                                }
                            })
                            return typeObj
                        })
                        item.couponUseProductType = typeArr
                        delete item.couponTypeList
                    }
                    return item
                })
                this.list = list;
            }
        },
    },
    watch: {},
}
</script>
