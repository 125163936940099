
/**
 * 优惠券状态
 *
 * **/
export const couponsStatusItem = [
    {
        value: 0,
        label: '未生效'
    },
    {
        value: 1,
        label: '已生效'
    },
    {
        value: 2,
        label: '已过期'
    },
]

/**
 * 优惠券类型
 *
 * **/
export const couponsTypeItem = [
    {
        value: 0,
        label: '满减'
    },
    {
        value: 1,
        label: '折扣'
    },
]
/**
 * 优惠券有效期
 *
 * **/
export const couponsValidityItem = [
    {
        value: 0,
        label: '同活动时间'
    },
    {
        value: 1,
        label: '固定天数'
    },
]
/**
 * 优惠券 减免类型 0  1随即金额
 *
 * **/
export const couponsDerateTypeItem = [
    {
        value: 0,
        label: '固定金额'
    },
    {
        value: 1,
        label: '随机金额'
    },
]
/**
 *  优惠券  优惠类型
 *
 * **/
export const preferentialTypeItem = [
    {
        value: 0,
        label: '新人有礼'
    },
    {
        value: 1,
        label: '邀请有奖'
    },
]

/**
 * 全活动类型
 *
 * **/
export const activityTypeItem = [
    {
        value: 0,
        label: '新人有礼'
    },
    {
        value: 1,
        label: '邀请有奖'
    },
]
/**
 * 活动状态 优惠券
 *
 * **/
export const activityStatusItem = [
    {
        value: 0,
        label: '未开始'
    },
    {
        value: 1,
        label: '进行中'
    },
    {
        value: 2,
        label: '已结束'
    },
    {
        value: 3,
        label: '提前结束'
    },
]

/**
 * 活动渠道
 *
 * **/
export const activityChannelItem = [
    // {value: 1, label: '', title: "TDS端"},
    // {value: 2, label: '游客端', title: "游客端"},
    // {value: 3, label: '', title: "[小程序]900出行同业端"},
    {value: 4, label: '出行小程序', title: "[小程序]900出行游客端"},
    // {value: 5, label: '', title: "[小程序]邮轮中心"},
    // {value: 6, label: '', title: "[小程序]烟台分销"},
    // {value: 7, label: '', title: "[小程序]即时预约"}
]

/**
 * 活动  活动入口 渠道
 * 0000
 * **/
export const activityEntranceItem = [
    {value: 1, label: '首页弹窗', field: 'homePage', w: '600', h: '720'},
    {value: 2, label: '付款后弹窗', field: 'payEnd', w: '600', h: '720'},
    {value: 3, label: '我的活动', field: 'myActivity', w: '702', h: '340'},
    {value: 4, label: '首页底部', field: 'foot', w: '750', h: '140'},
]
/**
 * 活动入口图片示例
 * **/
export const entranceSampleImgItem = {
    homePage: '',
    payEnd:'',
    myActivity: '',
    foot: '',
}

/**
 * 活动  发券 发放方式
 *
 * **/
export const activityIssueWayItem = [
    {value: 0, label: '自动发放'},
    {value: 1, label: '领取发放'},
]

/**
 * 活动  分享 分享方式
 *
 * **/
export const activityShareItem = [
    {value: 0, label: '微信好友', field: 'wxShareImg', w: '400', h: '320'},
    {value: 1, label: '生成海报', field: 'posterShareImg', w: '750', h: '1140'},
]
