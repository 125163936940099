<style lang="scss" scoped>
.pagination-foot {
    text-align: right;
    padding: 15px 10px;
    width: 100%;
    //border-top: 1px solid #DCDFE6;

    &::v-deep {
        .btn-prev, .btn-next {
            margin: 0 1px;
        }

        .el-pager {
            li {
                margin: 0 1px;
            }
        }

        .el-pagination__jump {
            margin-left: 13px;
        }
    }
}
</style>
<template>
    <div class="pagination-foot">
        <el-pagination
            background
            :pager-count="5"
            @size-change="changeSize"
            @current-change="changeCurrent"
            :current-page="pageInfo.currentPage"
            :page-sizes="pageSizes"
            :page-size="pageInfo.pageSize"
            layout="sizes, total, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
    </div>
</template>

<script type="text/ecmascript-6">

export default {
    //定义模版数据
    data() {
        return {
            pageInfo: {
                pageSize: 10,  // 每页显示条目个数
                currentPage: 1,  // 当前页数
            }
        }
    },
    props: {
        // 总条目数
        total: {
            type: Number,
            default: 1
        },
        // 每页显示个数选择器的选项设置
        pageSizes: {
            type: Array,
            default: () => ([10, 20, 30])
        },
    },
    // //主件被加载完成
    mounted: function () {
        this.handlePageInfo()
    },
    //定义事件方法
    methods: {
        changeSize(val) {
            this.pageInfo.currentPage = 1
            this.pageInfo.pageSize = val
            this.handlePageInfo()
        },
        // 当前页
        changeCurrent(val) {
            this.changePage(val);
            this.handlePageInfo()
        },
        changePage(val) {
            this.pageInfo.currentPage = val;
        },
        handlePageInfo() {
            let {
                    pageSize,
                    currentPage
                } = this.pageInfo,
                form = {
                    pageSize,
                    currentPage
                };
            this.$emit('update:pageInfo', form)
        }
    },
    //监听模版变量
    watch: {}

}
</script>
