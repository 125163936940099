<style lang="scss" scoped>
.coupons-list-form {
    &::v-deep {
        .common-list-search {
            .el-select{
                width: 100%;
            }
            .list-search-before {
                width: calc(100% - 286px);
                .el-form-item.el-form-item--small {
                    margin-bottom: 10px;
                    margin-right: 10px;
                }
            }

            .list-search-after {
                width: 286px;

                .el-button + .el-button {
                    margin-left: 10px;
                }
            }
        }
    }
}
</style>


<template>
    <div class="coupons-list-form">
        <com-list-search>
            <div slot="list-search-before">
                <el-form :inline="true" :model="form" size="small">
                    <el-form-item class="el-label_w">
                        <el-radio-group v-model="form.couponStatus">
                            <el-radio-button label="">全部状态</el-radio-button>
                            <el-radio-button
                                v-for="item in statusItem"
                                :key="item.value"
                                :label="item.value">
                                {{ item.label }}
                            </el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                    <!--                    <el-form-item class="el-label_w">-->
                    <!--                        <el-radio-group v-model="form.orderStatus">-->
                    <!--                            <el-radio-button label="">全部类型</el-radio-button>-->
                    <!--                            <el-radio-button-->
                    <!--                                v-for="item in typeItem"-->
                    <!--                                :key="item.value"-->
                    <!--                                :label="item.value">-->
                    <!--                                {{ item.label }}-->
                    <!--                            </el-radio-button>-->
                    <!--                        </el-radio-group>-->
                    <!--                    </el-form-item>-->
                    <el-form-item style="width: 150px">
                        <el-input v-model="form.couponNo" placeholder="优惠券ID"></el-input>
                    </el-form-item>
                    <el-form-item style="width: 150px">
                        <el-input v-model="form.couponName" placeholder="优惠券名称"></el-input>
                    </el-form-item>
                    <el-form-item style="width: 220px">
                        <el-select
                            v-model="form.cityId"
                            filterable
                            placeholder="优惠券适用城市">
                            <el-option
                                v-for="item in cityList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.shortCode">
                            </el-option>
                        </el-select>
                    </el-form-item>
<!--                    <el-form-item style="width: 280px">-->
<!--                        <el-select-->
<!--                            v-model="form.couponUseOrgInfo"-->
<!--                            filterable-->
<!--                            placeholder="优惠券适用机构"-->
<!--                            @change="changeOrg">-->
<!--                            <el-option-->
<!--                                v-for="item in orgList"-->
<!--                                :key="item.value"-->
<!--                                :label="item.label"-->
<!--                                :value="item.value">-->
<!--                            </el-option>-->
<!--                        </el-select>-->
<!--                    </el-form-item>-->
                    <el-form-item style="width: 230px">
                        <el-select
                            class="ml10"
                            v-model="form.operatorId"
                            filterable
                            placeholder="请选择运营商"
                            @change="changeOperator">
                            <el-option
                                v-for="item in operatorList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item style="width: 230px">
                        <el-select
                            class="ml10"
                            v-model="form.supplierId"
                            filterable
                            placeholder="请选择供应商">
                            <el-option
                                v-for="item in supplierList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="list-search-after" class="list-search-after_sec">
                <el-button type="primary" @click="handleAdd">添加优惠券</el-button>
                <el-button @click="handleReset">重置</el-button>
                <el-button type="primary" @click="handleSearch()">搜索</el-button>
            </div>
        </com-list-search>
    </div>

</template>

<script type="text/ecmascript-6">
import comListSearch from '@/www/components/comListSearch'
import {couponsStatusItem} from '../../../../data'
import {getCityData, getOperaIdSupplierList, getOperatorList} from "@/urls";
import {combinationForm} from '@/common/js/common'

export default {
    name: '',
    data() {
        return {
            statusItem: couponsStatusItem,
            // typeItem: couponsTypeItem,
            form: {
                couponStatus: '',
                // couponNo: '',
                // couponName: '',
                // cityId: '',
                // couponUseOrgInfo: ''
            },
            cityList: [],
            operatorList: [],
            supplierList: [],
            // orgList: []
        }
    },
    components: {
        comListSearch
    },
    //计算属性
    computed: {},
    //主件被加载完成
    mounted() {
        this.getCityList()
        this.getOperator();
        // this.getOrgList()
    },
    methods: {
        // 获取城市
        async getCityList() {
            const res = await getCityData(this, '2');
            if (res) this.cityList = res;
        },
        // 运营商列表
        async getOperator() {
            const res = await getOperatorList()
            if (res) this.operatorList = res
        },
        // 供应商列表
        async getSupplier(id) {
            const res = await getOperaIdSupplierList(id)
            if (res) this.supplierList = res
        },
        // 机构列表
        // async getOrgList(){
        //     const res = await getOrganizationList();
        //     if (res) {
        //         this.orgList = res
        //     }
        // },
        // 运营商选中
        changeOperator(id){
            this.getSupplier(id)
        },
        // 添加优惠券
        handleAdd() {
            this.$router.push('/addCoupon');
        },
        // 重置
        handleReset() {
            this.form = {
                couponStatus: '',
            }
            let form = combinationForm(this.form)
            this.$emit('update:form', form)
        },
        // 搜索
        handleSearch() {
            let {
                    couponStatus,
                    couponNo,
                    couponName,
                    cityId,
                    operatorId,
                    supplierId
                } = this.form,
                form = {
                    couponStatus,
                    couponNo,
                    couponName,
                    cityId,
                    operatorId,
                    supplierId
                };
            form = combinationForm(form)
            this.$emit('update:form', form)
        }
    },
    watch: {},
}
</script>
