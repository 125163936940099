<style lang="scss" scoped>

</style>


<template>
    <div>
        <el-table class="table-info"
                  border
                  :data="list"
                  v-loading="loading"
                  style="width: 100%">
            <el-table-column
                fixed
                prop="couponNo"
                label="优惠券ID"
                width="100">
            </el-table-column>
            <el-table-column
                prop="couponName"
                label="优惠券名称"
                width="130">
            </el-table-column>
            <el-table-column
                label="优惠券状态"
                width="100px">
                <template slot-scope="scope">
                    <p>
                        {{ scope.row.couponStatus | formatDesc(statusItem) }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column
                label="绑定状态"
                width="100px">
                <template slot-scope="scope">
                    <p>
                        {{ scope.row.activitiesId ? '已绑定' : '未绑定' }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column
                label="有效期"
                width="160px">
                <template slot-scope="scope">
                    <div v-if="scope.row.couponValidType === 0">
                        同活动时间
                        <p v-if="scope.row.activitiesId">{{ scope.row.activitiesStartTime | dateCus("yyyy-MM-dd hh:mm:ss") }} - {{ scope.row.activitiesEndTime | dateCus("yyyy-MM-dd hh:mm:ss") }}</p>
                    </div>
                    <div v-if="scope.row.couponValidType === 1 && scope.row.couponValidDay">
                        {{ scope.row.couponValidDay }} 天
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                label="优惠信息"
                width="170px">
                <template slot-scope="scope">
                    <p>
                        满减：
                        <span v-if="scope.row.couponDerateType === 0">{{ scope.row.couponPrice }}</span>
                        <span
                            v-if="scope.row.couponDerateType === 1">{{ scope.row.couponRandomMinPrice }}-{{ scope.row.couponRandomMaxPrice }}</span>
                        元{{ scope.row.couponDerateType | formatDesc(derateTypeItem) }}
                    </p>
                    <p>
                        最低满：{{ scope.row.couponMinUsePrice > 0 ? scope.row.couponMinUsePrice : '无门槛' }}
                    </p>
                </template>
            </el-table-column>
            <!--            <el-table-column-->
            <!--                label="返还|叠加"-->
            <!--                min-width="50px">-->
            <!--                <template slot-scope="scope">-->
            <!--                    <p>-->
            <!--                        {{scope.row.productForm | productTypeDesc}}-->
            <!--                    </p>-->
            <!--                </template>-->
            <!--            </el-table-column>-->
            <el-table-column
                label="适用城市"
                width="120px">
                <template slot-scope="scope">
                    <p v-for="(item, index) in scope.row.couponUseCityInfo" :key="item.cityId">
                        <span>{{ item.cityName }}</span><span
                        v-if="scope.row.couponUseCityInfo.length - index !== 1">，</span>
                    </p>
                </template>
            </el-table-column>
            <el-table-column
                label="适用类型"
                width="100px">
                <template slot-scope="scope">
                    <p v-for="(item, index) in scope.row.couponUseProductType" :key="item.value">
                        <span>{{ item.label }}</span><span
                        v-if="scope.row.couponUseProductType.length - index !== 1">，</span>
                    </p>
                </template>
            </el-table-column>
            <el-table-column
                label="适用机构"
                width="150px">
                <template slot-scope="scope">
                    <div v-if="scope.row.operatorName">
                        <p>{{scope.row.operatorName}}</p>
                        <p v-if="scope.row.supplierName">{{scope.row.supplierName}}</p>
                    </div>
                    <div v-else>全部</div>
                </template>
            </el-table-column>
            <el-table-column
                label="关联活动"
                width="150px">
                <template slot-scope="scope">
                    <p>
                        <span style="color: #319BF7">{{scope.row.activitiesNo}}</span>
                        {{scope.row.activitiesName}}
                    </p>
                </template>
            </el-table-column>
            <el-table-column
                label="创建和编辑"
                min-width="150px">
                <template slot-scope="scope">
                    <div>
                        <p v-if="scope.row.createUserName">
                            {{ scope.row.createUserName + '|'}}
                            {{ scope.row.createTime | dateCus("yyyy-MM-dd hh:mm") }}
                        </p>
                        <p v-if="scope.row.updateUserName && scope.row.createUserName !== scope.row.updateUserName">
                            {{ scope.row.updateUserName + '|'}}
                            {{ scope.row.updateTime | dateCus("yyyy-MM-dd hh:mm") }}
                        </p>
                    </div>
                </template>
            </el-table-column>
            <!--            <el-table-column-->
            <!--                label="下单时间"-->
            <!--                min-width="60">-->
            <!--                <template slot-scope="scope">-->
            <!--                    {{scope.row.createTime | dateCus("yyyy-MM-dd hh:mm:ss")}}-->
            <!--                </template>-->
            <!--            </el-table-column>-->
            <el-table-column
                :class-name="('table-info-operation')"
                label="操作"
                fixed="right"
                width="100">
                <template slot-scope="scope">
                    <el-button
                        type="text"
                        @click="goCouponDetail(scope.row.id, 2)">
                        查看
                    </el-button>
                    <el-button
                        v-if="!scope.row.activitiesId"
                        type="text"
                        @click="goCouponDetail(scope.row.id, 1)">
                        修改
                    </el-button>
<!--                    <el-button-->
<!--                        type="text"-->
<!--                        @click="handleCopy(scope.row.id)">-->
<!--                        复制-->
<!--                    </el-button>-->
                </template>
            </el-table-column>
        </el-table>
    </div>

</template>

<script type="text/ecmascript-6">

import {couponsDerateTypeItem, couponsStatusItem} from "../../../../data";

export default {
    data() {
        return {
            statusItem: couponsStatusItem,
            derateTypeItem: couponsDerateTypeItem,
        }
    },
    props: {
        list: {
            type: Array,
            default: () => ([])
        },
        loading: {
            type: Boolean,
            default: false
        },
    },
    components: {},
    //计算属性
    computed: {},
    //主件被加载完成
    mounted() {
    },
    methods: {
        // 优惠券详情
        goCouponDetail(id, type) {
                let path = ''
                switch (type) {
                    case 1:
                        path = 'editCoupon'
                        break;
                    case 2:
                        path = 'lookCoupon'
                        break
                }
                this.$router.push(`/${path}/` + id);
        },
        // 复制
        // handleCopy() {
        //
        // }

    },
    watch: {},
}
</script>
